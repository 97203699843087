const qrcode = require('./qrcode.js');

class MnbCode {
  constructor(config) {
    let config_defaults = {
      service: "HCT", // Service Tag
      version: "001", // Version: 001, 002
      encoding: "1", // Character set: 1 = UTF-8, 2 = ISO 8859-1
      bic: "", //'PBNKDEFF', // BIC
      name: "", // 'Max Muster', // Name of the beneficiary
      iban: "", //'DE02100100100006820101', // IBAN
      currency: "EUR",
      amount: "0.00", // Amount (Format „EUR#.##“)
      date: Date.now()
        .toString()
        .replace(/[^0-9]/g, "")
        .slice(0, -3),
      char: "", // Zweck (vierstelliger Buchstabencode, optional)
      ref: "", // Reference (strukturierter 35-Zeichen-Code gem. ISO 11649 RF Creditor Reference)
      shop: "",
      pos: "",
      receipt: "",
      customer: "",
      transaction: "",
      customerShopCard: "",
      navCode: "",
    };
    this._config = Object.assign(config_defaults, config);

    let girocode_string = `${this._config.service}
${this._config.version}
${this._config.encoding}
${this._config.bic}
${this._config.name}
${this._config.iban}
${this._config.currency}${this._config.amount}
${this._config.date}
${this._config.char}
${this._config.ref}
${this._config.shop}
${this._config.pos}
${this._config.receipt}
${this._config.customer}
${this._config.transaction}
${this._config.customerShopCard}
${this._config.navCode}
`;

    this._svg = qrcode(girocode_string);

    return this;
  }

  svg() {
    return this._svg;
  }

  svg_data_url() {
    return "data:image/svg+xml;utf8," + encodeURIComponent(this._svg);
  }
}

const mnbCode = function () {
  return new MnbCode(...arguments);
};

export { mnbCode };
