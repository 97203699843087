const CashControl = {
  rounded(total, currency) {
    let n = total;
    if (currency === "HUF") {
      return this.hufRound(n);
    }
    return n;
  },
  hufRound(x) {
    if (x > 0) {
      return x % 5 >= 2.5 ? parseInt(x / 5) * 5 + 5 : parseInt(x / 5) * 5;
    } else {
      return (
        (Math.abs(x) % 5 >= 2.5
              ? parseInt(Math.abs(x) / 5) * 5 + 5
              : parseInt(Math.abs(x) / 5) * 5) * -1
      );
    }
  },
};

export default CashControl;
