<template>
  <div class="row text-start custom-input whitebg-input align-items-center">
    <div class="col-4">{{ $t("pos.method") }}</div>
    <div class="col-8 bold-14">
      {{ selectedPayment?.name }}
    </div>
    <div class="col-4">{{ $t("pos.total") }}</div>
    <div class="col-8 bold-24">
      {{
        totalNeedToPay.toLocaleString("hu-HU", {
          style: "currency",
          currency: selectedWarehouse.default_currency,
          minimumFractionDigits: 0,
          maximumFractionDigits:
            selectedWarehouse.default_currency === "HUF" ? 0 : 2,
        })
      }}
    </div>
    <div
      class="col-12 text-center mt-2"
      v-if="selectedPayment?.type === 4 && wireQrCode !== null"
    >
      <img :src="wireQrCode" />
      <div class="mt-3">{{ paymentRef }}</div>
    </div>
    <div class="col-4 mt-4" v-if="selectedPayment?.type === 3">
      {{ $t("pos.payed") }}
    </div>
    <div class="col-8 mt-4" v-if="selectedPayment?.type === 3">
      <div class="fix-end-input">
        <input
          type="number"
          min="0"
          class="form-control"
          v-model="payedData"
          autofocus
        />
        <span>
          {{ selectedWarehouse.default_currency }}
        </span>
      </div>
    </div>
    <div class="col-4 mt-4" v-if="selectedPayment?.type === 3">
      {{ $t("pos.change") }}
    </div>
    <div
      class="col-8 bold-24 mt-4"
      v-if="selectedPayment?.type === 3 && payed - totalNeedToPay > 0"
    >
      {{
        (payed - totalNeedToPay).toLocaleString("hu-HU", {
          style: "currency",
          currency: selectedWarehouse.default_currency,
          minimumFractionDigits: 0,
          maximumFractionDigits:
            selectedWarehouse.default_currency === "HUF" ? 0 : 2,
        })
      }}
    </div>
  </div>
</template>

<script>
import { girocode } from "@dipser/epc-qr-code.js";
import { mnbCode } from "../../modules/mnbCode";
import { useStore } from "vuex";
import CashControl from "../../modules/CashControl";

export default {
  name: "Payment",
  props: {
    selectedPayment: Object,
    total: Number,
    payed: Number,
    paymentID: Number,
  },
  data() {
    return {
      store: useStore(),
      payedData: 0,
    };
  },
  mounted() {
    this.$emit("update:need", this.totalNeedToPay);
  },
  watch: {
    payedData() {
      this.$emit("update:payed", this.payedData);
    },
    totalNeedToPay() {
      this.$emit("update:need", this.totalNeedToPay);
    },
  },
  computed: {
    wireQrCode() {
      if (this.selectedWarehouse?.default_currency === "HUF") {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        let g = mnbCode({
          service: "HCT", // Service Tag
          version: "001", // Version: 001, 002
          encoding: "1", // Character set: 1 = UTF-8, 2 = ISO 8859-1
          bic: this.shop?.bic,
          name: this.shop?.bank_holder_name,
          iban: this.shop?.iban,
          currency: this.selectedWarehouse?.default_currency,
          amount: this.totalNeedToPay,
          date:
            tomorrow
              .toISOString()
              .replace(/[^0-9]/g, "")
              .slice(0, -3) + "+1",
          ref: this.paymentRef,
        });
        return g.svg_data_url();
      }
      if (this.selectedWarehouse?.default_currency === "EUR") {
        let g = girocode({
          service: "BCD", // Service Tag
          version: "001", // Version: 001, 002
          encoding: "2", // Character set: 1 = UTF-8, 2 = ISO 8859-1
          transfer: "SCT", // Identification: SCT = SEPA credit transfer
          bic: this.shop?.bic,
          name: this.shop?.bank_holder_name,
          iban: this.shop?.iban,
          currency: this.selectedWarehouse?.default_currency,
          amount: this.totalNeedToPay,
          char: "", // Zweck (vierstelliger Buchstabencode, optional)
          ref: this.paymentRef, // Reference (strukturierter 35-Zeichen-Code gem. ISO 11649 RF Creditor Reference)
          reason: "", // Verwendungszweck (max of 140 characters)
          hint: "", // Optional hint to user
        });
        return g.svg_data_url();
      }
      return null;
    },
    paymentRef() {
      return (
        this.selectedWarehouse?.prefix +
        ":" +
        this.loggedUser.id +
        "-" +
        this.paymentID
      );
    },
    currencies() {
      return this.store.state.currencies;
    },
    warehouses() {
      return this.store.state.warehouses;
    },
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
    loggedUser() {
      return this.store.state.user;
    },
    selectedWarehouse() {
      return this.warehouses.find((e) => e.id === this.warehouse);
    },
    totalNeedToPay() {
      if (this.selectedPayment?.type === 3) {
        return this.roundedCashTotal;
      }
      return this.total;
    },
    roundedCashTotal() {
      return CashControl.rounded(
        this.total,
        this.selectedWarehouse?.default_currency
      );
    },
  },
};
</script>
