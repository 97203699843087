<template>
  <div v-on:mouseout="focusOut" v-on:mouseenter="focusClickPosition">
    <div v-on:click="focusClickPosition">
      {{ $t("bankDetailsToast.bank") }}
      <span v-if="value?.number">{{ value.number }}</span>
      <span v-else-if="value?.iban">{{ value.iban }}</span>
      <span v-else-if="value?.bank_code">{{ value.bank_code }}</span>
    </div>
    <div
      class="toast fade popup position-fixed text-start"
      aria-live="assertive"
      aria-atomic="true"
      ref="modal"
      :style="{ top: clickY + 'px', left: clickX + 'px' }"
      v-on:mouseover="focusIn"
    >
      <div class="toast-header">
        <strong class="me-auto"> {{ $t("bankDetailsToast.bankDetails") }} </strong>
      </div>
      <div class="toast-body">
        <div
            v-for="(val, key) in currencies[currencies[value.currency] !== undefined ? value.currency : 'default'].fields"
            :key="key"
            class="row"
        >
          <div class="col-5">
          {{val}}
          </div>
          <div class="col-7 fw-bold">
            {{value[key]}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bankCurrencies from "@/modules/BankCurrencies";

const bootstrap = require("bootstrap");

export default {
  name: "BankDetailsToast",
  props: {
    id: Number,
    type: String,
    row: Object,
    modelValue: Object,
  },
  data() {
    return {
      value: this.modelValue,
      clickX: 0,
      clickY: 0,
      modal: null,
      currencies: bankCurrencies,
      hide: true,
    };
  },
  mounted() {
    this.modal = new bootstrap.Toast(this.$refs.modal, { autohide: false });
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  emits: ["update:modelValue", "change"],
  methods: {
    focusClickPosition(event) {
      this.clickX =
        document.documentElement.clientWidth - 300 < event.clientX
          ? document.documentElement.clientWidth - 300
          : event.clientX - 30;
      this.clickY =
        document.documentElement.clientHeight - 180 < event.clientY
          ? document.documentElement.clientHeight - 180
          : event.clientY - 20;
      this.modal.show();
    },
    focusOut() {
      this.hide = true;
      setTimeout(() => {
        if (this.hide) {
          this.modal.hide();
        }
      }, 500);
    },
    focusIn() {
      this.hide = false;
    },
  },
};
</script>
