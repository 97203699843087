const BankCurrencies = {
  default: {
    fields: {
      holder: "Account holder",
      iban: "IBAN",
      bank_code: "BIC",
    },
  },
  HUF: {
    fields: {
      holder: "Account holder",
      number: "Account number",
      bank_code: "BIC",
    },
  },
  EUR: {
    fields: {
      holder: "Account holder",
      iban: "IBAN",
      bank_code: "BIC",
    },
  },
  AUD: {
    fields: {
      holder: "Account holder",
      number: "IBAN",
      bank_code: "BSB",
    },
  },
  CAD: {
    fields: {
      holder: "Account holder",
      number: "institution number",
      bank_code: "BSB",
      transit_code: "transit number",
    },
  },
  GBP: {
    fields: {
      holder: "Account holder",
      number: "Account number",
      bank_code: "Sort code",
    },
  },
  NZD: {
    fields: {
      holder: "Account holder",
      number: "Account number",
    },
  },
  INR: {
    fields: {
      holder: "Account holder",
      number: "Account number",
      bank_code: "IFSC",
    },
  },
  /* PLN: {
      fields: {
        holder: "Account holder",
        number: "Account number",
      },
    },*/
  USD: {
    fields: {
      holder: "Account holder",
      number: "institution number",
      bank_code: "ACH number",
      transit_code: "Wire routing number",
    },
  },
};

export default BankCurrencies;
